<template>
  <v-card>
    <div class="sticky-header-bar">
      <v-card-title
        class="d-flex justify-space-between py-12"
      >
        <div>
          {{ $t('tender.preview') }}
        </div>
        <div>
          <v-btn
            dark
            outlined
            color="error"
            class="mr-2"
            @click.prevent="$router.push({ name: 'admin-tender-edit', params: { id: $route.params.id } })"
          >
            {{ $t('general.close') }}
          </v-btn>
        </div>
      </v-card-title>
    </div>

    <div>
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="n in steps">
            <v-stepper-step
              :key="`${n}-step`"
              :complete="currentStep > n"
              :step="n"
              editable
            >
              {{ stepperTitles[n - 1].title }}
            </v-stepper-step>

            <v-divider
              v-if="n !== steps"
              :key="n"
            ></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-for="n in steps"
            :key="`${n}-content`"
            :step="n"
          >
            <v-card
              class="mb-12"
              color="grey lighten-1"
              height="auto"
            >
              <v-col v-if="n === 1">
                <div class="pt-6 mb-2 ">
                  <div class="w-full pl-2">
                    <h2>{{ $t('tender.mainData') }}</h2>
                  </div>
                  <v-row
                    cols="12"
                    class="pa-2"
                  >
                    <v-col
                      cols="12"
                      md="3"
                      lg="4"
                    >
                      {{ $t('tender.date') }} {{ tenderDate }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="4"
                    >
                      {{ $t('tender.platformReferenceNumber') }} {{ platformReferenceNumber }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="4"
                    >
                      {{ $t('tender.clientReferenceNumber') }} {{ clientReferenceNumber }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="4"
                    >
                      {{ $t('tender.tenderCategory') }} {{ tenderCategory ? $t(tenderCategory.name) : '' }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="4"
                    >
                      {{ $t('tender.company') }} {{ company ? company.name : '' }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      lg="4"
                    >
                      {{ $t('tender.tenderOwner') }} {{ tenderOwner ? tenderOwner.first_name : '' }} {{ tenderOwner ? tenderOwner.last_name : '' }}
                    </v-col>
                  </v-row>

                  <v-row
                    cols="12"
                    class="px-2"
                  >
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      {{ $t('tender.acceptingOffersFrom') }} {{ acceptingOffersFrom }}
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      {{ $t('tender.acceptingOffersTo') }} {{ acceptingOffersTo }}
                    </v-col>
                  </v-row>

                  <v-row
                    cols="12"
                    class="px-2"
                  >
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      {{ $t('tender.platformCategory') }} {{ platformCategory ? $t(platformCategory.name) : '' }}
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      {{ $t('tender.procedureType') }} {{ procedureType ? $t(procedureType.name) : '' }}
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      {{ $t('tender.tenderTypeStateAreaContract') }} {{ $t(tenderTypeStateAreaContract) }}
                    </v-col>
                  </v-row>

                  <div class="w-full pl-2 pb-4 pt-4 d-flex justify-space-between">
                    <h2>{{ $t('tender.addressData') }}</h2>
                  </div>

                  <v-row
                    cols="12"
                    class="px-2 py-0"
                  >
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <strong>{{ $t('tender.mainCompanyAddress') }}</strong>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      lg="6"
                    >
                      {{ $t('tender.address') }}: {{ company ? company.address : '' }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('tender.zipCode') }}: {{ company ? company.zip_code : '' }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('tender.city') }}: {{ company ? company.city : '' }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('tender.country') }}: {{ company ? company.country : '' }}
                    </v-col>
                  </v-row>

                  <v-row
                    v-for="(address, index) in addresses"
                    :key="index"
                    cols="12"
                    class="px-2 py-0 position-relative"
                  >
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <strong>
                        {{ $t($store.state.addressTypeOptions.filter(
                          item =>item.key === address.type
                        )[0].name) }}
                      </strong>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('companies.name') }} {{ address.contact.name }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('tender.address') }} {{ address.contact.address }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('tender.zipCode') }} {{ address.contact.zip_code }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('tender.city') }} {{ address.contact.city }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('tender.country') }} {{ address.contact.country }}
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      {{ $t('companies.phone') }} {{ address.contact.phone }}
                    </v-col>
                  </v-row>

                  <div class="w-full pl-2 pt-6">
                    <h2>{{ $t('tender.title') }}</h2>
                  </div>

                  <v-col cols="12">
                    {{ tenderTitle }}
                  </v-col>

                  <div class="w-full pl-2">
                    <h2>{{ $t('tender.tenderDescription') }}</h2>
                  </div>

                  <v-row
                    cols="12"
                    class="no-gutters px-2 mb-4"
                  >
                    {{ mainDescription }}
                  </v-row>
                </div>
              </v-col>

              <div
                v-for="(formItem, index) in formItems"
                :key="index"
              >
                <div
                  v-if="formItem.stepperId === n"
                  class="w-full pt-6 pa-2 mb-2 "
                >
                  <div
                    class="container-divider"
                  >
                    <v-divider></v-divider>
                    <div>C-{{ formItem.containerId }}</div>
                  </div>

                  <v-row>
                    <v-col
                      cols="12"
                      md="10"
                      lg="9"
                      class="bold"
                    >
                      <strong>{{ formItem.containerName ? formItem.containerName : $t('tender.dataContainer') + (index + 1) }}</strong>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="col-12">
                      <h2>
                      </h2>
                    </v-col>

                    <v-col
                      v-if="formItem.subtitle !== null"
                      cols="12"
                      style="position: relative"
                    >
                      {{ formItem.subtitle }}
                    </v-col>

                    <v-col
                      v-if="formItem.description !== null"
                      cols="12"
                      style="position: relative"
                    >
                      {{ formItem.description }}
                    </v-col>
                  </v-row>

                  <v-row
                    v-for="(formRow, row) in formItem.rows"
                    :key="row"
                    class="my-0"
                  >
                    <v-radio-group
                      :v-model="(formItem.radioGroup + index + row)"
                      row
                      class="w-full my-0"
                    >
                      <v-col
                        v-for="(item, col) in formRow"
                        :key="col"
                        cols="12"
                        :md="item.type !== 'textarea' ? 4 : 12"
                        :lg="item.type !== 'textarea' ? 2 : 12"
                        style="position: relative"
                        class="my-0"
                      >
                        <span
                          v-if="(item.type === 'text' || item.type === 'number') && !item.isRequested"
                        >
                          {{ item.value && !item.isRequested ? item.value : '' }}
                        </span>

                        <v-text-field
                          v-if="(item.type === 'text' || item.type === 'number') && item.isRequested"
                          v-model="item.value"
                          :type="item.type"
                          :label="item.label"
                          dense
                          outlined
                        >
                        </v-text-field>

                        <span
                          v-if="item.type === 'file'"
                        >
                          <a
                            :href="`${appConfig.apiURL}file/${item.path}`"
                            download=""
                            target="_blank"
                          >{{ item.originalName }}</a>
                        </span>

                        <v-radio
                          v-if="item.type === 'radio'"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          dense
                          outline
                          :disabled="!item.isRequested"
                          :name="`group` + (index + row)"
                        >
                        </v-radio>

                        <v-checkbox
                          v-if="item.type === 'checkbox'"
                          v-model="item.value"
                          :type="item.type"
                          :label="item.label"
                          dense
                          outlined
                        >
                        </v-checkbox>

                        <span
                          v-if="item.type === 'textarea' && !item.isRequested"
                          class="my-3 d-inline-block w-full"
                        >
                          {{ item.value && !item.isRequested ? item.value : '' }}
                        </span>

                        <v-textarea
                          v-if="item.type === 'textarea' && item.isRequested"
                          v-model="item.value"
                          :type="item.type"
                          :label="item.label"
                          dense
                          outlined
                        >
                        </v-textarea>

                        <div
                          v-if="item.type === 'date'"
                        >
                          {{ item.value ? item.value : item.label }}
                        </div>
                      </v-col>
                    </v-radio-group>
                  </v-row>
                </div>
              </div>
            </v-card>

            <v-btn
              dark
              outlined
              color="error"
              class="mr-2"
              @click.prevent="$router.push({ name: 'admin-tender-edit', params: { id: $route.params.id } })"
            >
              {{ $t('general.close') }}
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
        <v-stepper-header>
          <template v-for="n in steps">
            <v-stepper-step
              :key="`${n}-step`"
              :complete="currentStep > n"
              :step="n"
              editable
            >
              {{ stepperTitles[n - 1].title }}
            </v-stepper-step>

            <v-divider
              v-if="n !== steps"
              :key="n"
            ></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </div>
  </v-card>
</template>

<script>

import {
  mdiArrowDown,
  mdiArrowUp,
  mdiCalendar,
  mdiCircleEditOutline,
  mdiCloseCircle,
  mdiCloseOutline,
  mdiMinus,
  mdiPencilCircle,
  mdiPlus,
} from '@mdi/js'
import axiosIns from '@axios'
import appConfig from '@/config/appConfig'

export default {
  name: 'Preview',
  data() {
    const addresses = []
    const tenderDate = null
    const platformReferenceNumber = ''
    const clientReferenceNumber = ''
    const tenderCategory = ''
    const selectedCompany = ''
    const company = { name: '' }
    const tenderOwner = { name: '' }
    const procedureType = ''
    const tenderTypeStateAreaContract = ''
    const tenderTitle = ''
    const internalTitle = ''
    const mainDescription = ''
    const acceptingOffersFrom = ''
    const acceptingOffersTo = ''
    const platformCategory = null
    const formItems = []
    const steps = 1
    const stepperTitles = [{ title: 'Step 1' }]

    return {
      addresses,
      appConfig,
      stepperTitles,
      currentStep: 1,
      tenderDate,
      platformReferenceNumber,
      clientReferenceNumber,
      tenderCategory,
      platformCategory,
      procedureType,
      tenderTypeStateAreaContract,
      selectedCompany,
      company,
      tenderOwner,
      tenderTitle,
      internalTitle,
      mainDescription,
      acceptingOffersFrom,
      acceptingOffersTo,
      formItems,
      steps,
      icons: {
        mdiCloseOutline,
        mdiCircleEditOutline,
        mdiPencilCircle,
        mdiCloseCircle,
        mdiCalendar,
        mdiPlus,
        mdiMinus,
        mdiArrowUp,
        mdiArrowDown,
      },
    }
  },
  watch: {
    steps(val) {
      if (this.currentStep > val) {
        this.currentStep = val
      }
    },
  },
  created() {
    this.getTenderData()
  },
  methods: {
    getTenderData() {
      axiosIns.get(`/admin/tenders/${this.$route.params.id}`)
        .then(res => {
          const { tender } = res.data
          this.tenderDate = tender.tender_date
          this.steps = tender.steps
          this.stepperTitles = JSON.parse(tender.stepper_titles)
          this.tenderTitle = tender.title
          this.internalTitle = tender.internal_title
          this.tenderDate = tender.tender_date
          this.acceptingOffersFrom = tender.accepting_offers_from_date
          this.acceptingOffersTo = tender.accepting_offers_to_date
          this.description = tender.description
          this.platformReferenceNumber = tender.platform_reference_number
          this.clientReferenceNumber = tender.client_reference_number
          this.company = tender.company
          this.tenderOwner = tender.owner
          this.mainDescription = tender.description
          // eslint-disable-next-line prefer-destructuring
          this.platformCategory = this.$store.state.platformCategories.filter(item => item.key === tender.platform_category)[0]
          // eslint-disable-next-line prefer-destructuring
          this.tenderCategory = this.$store.state.tenderCategories.filter(item => item.key === tender.tender_category)[0]
          // eslint-disable-next-line prefer-destructuring
          this.procedureType = this.$store.state.procedureType.filter(item => item.key === tender.procedure_type)[0]
          this.tenderTypeStateAreaContract = tender.state_area_contract
          const tenderContainers = tender.tender_containers
          this.addresses = tender.tender_contacts

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < tenderContainers.length; i++) {
            const containerItem = {
              containerName: tenderContainers[i].container_name,
              rows: JSON.parse(tenderContainers[i].rows),
              stepperId: tenderContainers[i].stepper_id,
              subtitle: tenderContainers[i].subtitle,
              containerId: tenderContainers[i].container_id,
            }

            this.formItems.push(containerItem)
          }
        })
        .catch()
    },
  },
}
</script>

<style scoped lang="scss">

.bottom-action-icon {
  bottom: 1.75em;
  right: 0
}

.top-action-icon {
  top: 0;
  right: 0
}

.container-divider {
  padding: 1em .4em;
  font-weight: bold;
  margin-top: 2em;
}

.container-divider div {
  background: white;
  margin-top: -1.3em;
  width: 100px;
  padding: .5em;
  border: 1px solid #ddd;
  border-radius: 3px;
}

</style>
